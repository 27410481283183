.period {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
  margin-bottom: 22px;
  border-radius: 15px;
  padding: 0px 23px;
  background: #f6f7fb;
  width: 100%;
  height: 73px;
  align-items: center;
  justify-content: space-between;
  transition: $theme-transition;

  .countdown {
    margin-left: 15px;
    margin-right: 15px;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: 5px;
  }
}

.dark-mode {
  .period {
    background: transparent;
  }
}

@media only screen and (max-width: $mobile) {
  .period {
    height: auto;
    flex-wrap: wrap;
    padding: 0px 14px;

    background: transparent;
    .countdown {
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
