.period-sections-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loader {
    height: 15px;

    border-radius: 10px;
  }
  &-top {
    margin-bottom: 10px;
    .loader {
      width: 100px;
    }
  }
  &-bottom {
    justify-content: space-between;
    .loader {
      width: calc(100% / 4 - 20px);
    }
  }
}
