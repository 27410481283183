@import "./Desktop/style.scss";
@import "./Mobile/style.scss";

.dark-mode {
  .home-header-counters {
    &-element {
      &-title {
        color: white;
      }
      &-count {
        color: white;
      }
    }
  }
}
