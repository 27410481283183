.influencers-loader {
  width: 100%;
  height: 100%;

  &-desktop {
    width: 100%;
    .flex {
      align-items: center;
      justify-content: flex-start;
    }
    .loader-element {
      padding: 10px;
      height: 100%;
      display: flex;
      margin-right: 10px;
      flex-direction: column;
      &-circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      &-short {
        margin-left: 10px;
        height: 10px;
        width: 70px;
      }
      &-long {
        height: 10px;
        width: 120px;

        margin-top: auto;
      }
    }
  }
}
