.cookie-policy {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: 72px;
  background: #0b2239;
  transition: $theme-transition;
  font-family: "Inter", sans-serif;
  &-content {
    height: 100%;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    p {
      @extend .text;

      color: white;
      flex: 1;
      font-size: 0.85rem;
      line-height: 1.25rem;
      padding-right: 40px;
    }
    &-btns {
      align-items: center;
      button {
        font-weight: 600;
        font-size: 0.75rem;
        font-family: "Inter", sans-serif;
      }
      &-cancel {
        background: transparent;
        color: #ffc843;
        border: none;
        border-bottom: 1px solid #ffc843;
        margin-right: 15px;
      }
      &-accept {
        padding: 0px 12px;
        border: 0px;
        height: 36px;
        color: #0b2239;
        border-radius: 2px;
        background: #ffc843;
      }
    }
  }
}

.dark-mode {
  .cookie-policy {
    background: #58595a;
  }
}

@media only screen and (max-width: $mobile) {
  .cookie-policy {
    height: auto;
    &-content {
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 40px;
      p {
        text-align: center;
        padding: 0px;
      }
      &-btns {
        margin-top: 40px;
      }
    }
  }
}
