.period-element {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  padding-right: 10px;
  height: 100%;
  width: calc(100% / 4);
  justify-content: space-between;

  position: relative;

  &:nth-child(1) {
    padding-left: 0px;
  }
  &-tooltip {
    word-break: break-all;
  }
  &::after {
    position: absolute;
    right: 0px;
    height: 70%;
    top: 50%;
    transform: translate(0, -50%);
    background: #dddddd;
    width: 1px;
    content: "";
  }
  &:last-of-type {
    padding-right: 0px;
    &::after {
      display: none;
    }
  }

  &-name,
  &-full {
    font-size: 0.625rem;
    @extend .text;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
  a {
    cursor: pointer;
  }

  &-name {
    cursor: default;
    flex: 1;
    padding-right: 6px;
  }
  &-full {
    position: absolute;
    top: calc(100% - 10px);
    padding: 10px;
    border-radius: 5px;
    background: #f6f7fb;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all;
  }

  img {
    margin-right: 5px;
    background-size: 10px 10px;
    object-position: top;
  }
  &-title {
    color: #92929d;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.563rem;
    margin-bottom: 7px;
  }
  &-count {
    text-align: right;
    color: #92929d;

    font-size: 0.675rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flex {
    align-items: center;
  }
  &-content {
    width: 100%;
  }
}

@media only screen and (max-width: $mobile) {
}
