.container {
  width: 34px;
  display: flex;
  justify-content: space-between;
  .dot {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: #ffc542;

    animation: loader 0.9s infinite alternate;
  }

  .one {
    //  border: #ab02ab 2px solid;
  }

  .two {
    animation-delay: 0.2s;
    //  border: 2px solid #623790;
  }

  .three {
    animation-delay: 0.4s;
    //  border: 2px solid #267a8c;
  }

  .four {
    animation-delay: 0.6s;
    // border: 2px solid #01a98d;
  }
}

@keyframes loader {
  to {
    background: rgba(255, 197, 66, 0.2);
  }
}
