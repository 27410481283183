.list {
  position: relative;
  width: calc(100% / 4 - 20px);
  max-width: 100%;
  transition: 0.3s all;

  &-left {
    transform: translate(-30%);
    opacity: 0;
  }
  &-mobile-toggle {
    background: transparent;
    border: none;
    margin-left: auto;
    margin-right: auto;
    color: #92929d;
    font-size: 0.75rem;
    width: 100%;
    margin-top: 20px;
  }
  &-title {
    margin-bottom: 20px;
    align-items: center;
    padding-left: 15px;
    &-images {
      margin-right: 10px;
      position: relative;
      width: 26px;
      height: 26px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 0.938rem;
      letter-spacing: 0.001rem;
      @extend .text;
    }
  }
  .card {
    position: absolute;
    will-change: transform, height, opacity;
    width: 100%;
    padding-bottom: 6px;
  }
}

@media only screen and (max-width: 1100px) {
  .list {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: $mobile) {
  .list {
    width: 100%;

    &-title {
      h3 {
        font-size: 0.875rem;
      }
      &-images {
        width: 22px;
        height: 22px;
      }
    }
  }
}
