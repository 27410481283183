.view-selector-option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 40px;
  text-align: center;
  position: relative;

  transition: 0.2s all;
  .flex {
    align-items: center;
    cursor: pointer;
  }

  &-extra {
    font-size: 6px;
    margin-left: 10px;
    margin-top: 2px;
  }
  &-name {
    @extend .text;
    font-size: 0.813rem;
    white-space: nowrap;
    text-transform: none !important;
  }
  &-img {
    margin-right: 0.375rem;
    object-fit: contain;
    transform: scale(1.15);
    transform-origin: bottom;
  }
  &-active {
    .view-selector-option-name {
      font-weight: 600;
    }
  }
}
.MuiTouchRipple-root {
  display: none !important;
}

@media only screen and (max-width: $mobile) {
  .view-selector-option {
    min-width: auto;
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    &-name {
      white-space: nowrap;
    }
  }
}
