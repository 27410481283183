@import "./Mobile/style.scss";
@import "./Desktop/style.scss";

.influencers-list {
  width: fit-content;
  overflow: auto;
  flex: 1;
}

@media only screen and (max-width: $mobile) {
  .influencers-list {
    order: 2;
    width: 100%;
    overflow: hidden;
  }
}
