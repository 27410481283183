@import "../components/InfluencersContainer/style.scss";
@import "../components/CountDown/style.scss";
@import "../components/Period/style.scss";
@import "../components/ViewSelector/style.scss";
@import "../components/ListsContainer/style.scss";
@import "../components/Popups/VerifiedUsersPopup/style.scss";

.flex {
  display: flex;
}
.home {
  position: relative;

  min-height: 100vh;
  transition: $theme-transition;
  &-flex {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .footer {
    order: 5;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: $mobile) {
  .home {
    &-grid {
      width: calc(100% - 20px);
    }
  }
}
