.influencer {
  padding: 10px 14px 10px 17px;
  position: relative;
  width: fit-content;
  max-width: 250px;
  &:after {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    height: 70%;
    width: 1px;
    background: #dddddd;
    content: "";
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  &-top {
    margin-bottom: 5px;
    width: 100%;
    .img {
      margin-right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: contain;
    }
    &-content {
      overflow: hidden;
      flex: 1;
    }
  }
  &-name {
    font-size: 0.563rem;
    margin-bottom: 5px;
    font-weight: 600;
    @extend .text;
    @extend .overflow-text;
    width: 95%;
  }
  &-username {
    font-size: 0.625rem;
    font-weight: 500;
    @extend .text;
    @extend .overflow-text;
    width: 95%;
  }

  &-bottom {
    strong {
      font-size: 0.625rem;
      font-weight: 700;
      margin-right: 3px;
      @extend .text;
    }
    p {
      font-size: 0.625rem;
      @extend .text;
    }
    span {
      align-items: center;
    }
    &-left {
      margin-right: 12px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .influencer {
    padding-left: 0px;
    padding-right: 0px;
    width: calc(50% - 15px);
    &-bottom {
      span {
        flex-direction: column;
        strong {
          margin-bottom: 3px;
        }
      }
    }
    &:nth-child(even) {
      margin-left: auto;
      &:after {
        display: none;
      }
    }
    max-width: none;
    &::before {
      position: absolute;
      width: 100%;
      top: 0px;
      background: #dddddd;
      height: 1px;
      content: "";
    }
    &:after {
      right: -15px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      &:before {
        display: none;
      }
    }
  }
}
