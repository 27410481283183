@import "components/VerifiedUsersViewCustom/style.scss";
@import "./components/AllTweetsViewCustom/style.scss";
@import "./components/ViewOption/style.scss";

.view-selector {
  order: 3;
  border-bottom: 2px solid #f1f1f1;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: $theme-transition;
  .MuiTabs-root {
    margin-bottom: -2px;
  }
  .view-selector-custom {
    position: relative;
    margin-left: 10px;

    &-btn {
      width: 15px;
      height: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.dark-mode {
  .view-selector {
    border-bottom: 2px solid #404040;
  }
}

@media only screen and (max-width: $mobile) {
  .view-selector {
    z-index: 999;
    background: #fafafb;
    position: sticky;
    top: 42px;
    display: block;
    width: 100%;

    justify-content: flex-start;
    height: 50px;

    .view-selector-custom {
      position: static;
    }
  }
  .dark-mode {
    .view-selector {
      background: #2b2b2b;
    }
  }
}
