.countdown {
  align-items: center;
  flex-direction: column;
  height: 100%;

  width: 230px;
  justify-content: center;
  position: relative;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 58px;
    background: #dddddd;
    top: 50%;
    transform: translate(0, -50%);
  }
  &::before {
    left: 0px;
  }
  &::after {
    right: 0px;
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 70px;
    &::after {
      position: absolute;
      right: 0px;
      top: 3px;
      content: ":";
      color: #ffc843;
      font-size: 1rem;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  &-time {
    font-size: 1.375rem;
    @extend .text;
    margin-bottom: 5px;
    font-weight: 300;
    width: 100%;
    text-align: center;
  }
  &-text {
    font-size: 0.625rem;
    letter-spacing: 0.006rem;
    color: #92929d;
  }
  &-title {
    @extend .text;
    font-size: 0.875rem;
    margin-bottom: 10px;
  }
}

.dark-mode {
  .countdown-text {
    color: white;
  }
}

@media only screen and (max-width: $mobile) {
  .countdown {
    &-title {
      font-size: 0.75rem;
    }
    &-text {
      font-size: 0.7rem;
    }
  }
}
