.tooltip {
  font-size: 0.7rem !important;
  max-width: 280px;
  line-height: 1.2rem;
  text-align: left;
}

.MuiTooltip {
  &-arrow {
    color: #171725 !important;
  }
  &-tooltip {
    display: flex;
    background-color: #171725 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 0.688rem !important;
    line-height: 1.2rem !important;
    font-weight: 400 !important;
  }
}
