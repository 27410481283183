.list-item-loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 220px;
  height: 220px;
  transition: 0.2s all;
}

@media only screen and (max-width: $mobile) {
  .list-item-loader {
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translate(-50%);
    width: 310px;
    height: 310px;
  }
}
