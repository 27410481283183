.list-loader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: 0.2s all;
  .lottie {
    width: 100%;
  }
  &-active {
    opacity: 1;
  }

  &-section {
    @extend .list;

    &-element {
      width: 100%;
      height: 45px;
      margin-bottom: 8px;
      border-radius: 15px;
    }
    &-title {
      margin-bottom: 20px;
      &-left {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 20px;
      }
      &-right {
        width: 50%;
        height: 20px;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .list-loader {
    flex-direction: column;

    &-mobile {
      margin-bottom: 20px;
      figure {
        height: 20px;
        margin-bottom: 20px;
      }
      flex-direction: column;
      &-title {
        width: 50%;
      }
      &-element {
        width: 100%;
      }
    }
  }
}
