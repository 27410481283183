$main-font: "Poppins", sans-serif;
body {
  font-family: $main-font;
  background: $light-mode-bg;
  transition: $theme-transition;
}

.text {
  transition: $theme-transition;
  color: $main-text-color;
  letter-spacing: 0.006rem;
}
a {
  cursor: pointer;
}
.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-mode {
  .text {
    color: $dark-mode-text-color;
  }
}

.loader {
  animation: glow 1.5s ease-in-out infinite alternate;
  background: lightgray;
  border-radius: 20px;
}

.glow {
  font-size: 80px;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.2;
  }
}
.word-break {
  word-break: break-all;
}
.dark-mode {
  background: $dark-mode-bg;
  .loader {
    background: rgba(255, 255, 255, 0.1);
  }
}
