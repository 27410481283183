.influencers-join {
  padding: 5px;

  margin-left: auto;
  background: #e7e7e7;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 90px;
  position: relative;
  transition: $theme-transition;
  p {
    font-size: 0.5rem;

    font-weight: 600;
    line-height: 0.75rem;
    @extend .text;
  }
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}

.dark-mode {
  .influencers-join {
    background: #3a3a3a;
  }
}

@media only screen and (max-width: $mobile) {
  .influencers-join {
    order: 1;
    margin-left: 0px;
    width: 100%;
    height: 0px;
    margin-top: 15px;
    margin-bottom: 25px;
    background: transparent;
    flex-direction: row;
    p {
      font-size: 0.688rem;
    }
    img {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
  }
  .dark-mode {
    .influencers-join {
      background: transparent;
    }
  }
}
