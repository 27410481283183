.img {
  overflow: hidden;
  position: relative;
  .img-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
