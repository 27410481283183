.navbar {
  margin-top: 15px;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  .theme-toggle {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  @extend .text;
  font-size: 0.875rem;
  font-weight: 200;
  img {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
