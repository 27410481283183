.policy {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  padding-top: 50px;
  padding-right: 10px;
  &-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  * {
    font-family: $main-font;
    color: black;
  }
  ol {
    padding-left: 20px;
  }
  strong {
    font-weight: 600;
  }
  a {
    color: black;
  }
  li {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  span {
    font-size: 0.938rem;
    line-height: normal;
  }
}

.dark-mode {
  .policy {
    * {
      color: white;
    }
  }
}
