.ReactModalPortal {
  position: relative;
}
.modal {
  z-index: 9999 !important;
  width: 100%;
  height: 100%;
  &-overlay {
    background: rgba(45, 45, 45, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.2s all;
  }
  &-content {
    width: fit-content;
    max-width: calc(100% - 20px);
    outline: none;
    transition: 0.2s all;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 30%;
    text-align: center;
  }
}
@media only screen and (max-width: $mobile) {
  .modal {
    &-content {
      top: 50%;
    }
  }
}
