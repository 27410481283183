.list-item {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: inset 0px -1px 0px #dcdce0;
  border-radius: 15px;
  border: none;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  transition: $theme-transition;

  &-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #ffc843;
    opacity: 0;
    border-radius: 15px;
    transition: 0.15s all;
    transform-origin: bottom;
  }
  &-active {
    .list-item-bg {
      opacity: 1;
    }
    .list-item-hashtags-list .tweet-content strong {
      color: black;
    }
  }
  &-wrapper {
    position: relative;
  }

  &-custom {
    position: relative;
    flex: 1;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  &-top {
    height: 43px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 15px;
    &-tooltip {
      &-btn {
        width: 100%;
        height: 100%;
        z-index: 999;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
      }
    }

    &-name {
      margin-right: 30px;
      border-bottom: 1px solid transparent;
      font-weight: 600;
      @extend .text;
      font-size: 0.688rem;
      white-space: nowrap;
      flex: 1;
      line-height: normal;
      overflow: hidden;
      position: relative;
      align-self: center;
      text-overflow: ellipsis;

      padding-right: 20px;

      z-index: 1;
    }

    &-counter {
      z-index: 1;
      font-size: 0.813rem;
      color: #92929d;
      text-align: right;
      position: relative;
    }
  }
  &-active {
    opacity: 1;
  }
  &-open {
    border: 1px solid #ffc542;
    box-shadow: inset 0px -1px 0px #dcdce0;
    .list-item-content {
      &-name {
        border-bottom: 1px solid black;
      }
    }
  }

  &:hover {
    border: 1px solid #ffc542;
  }
  &-active {
    .list-item-flame {
      opacity: 1;
    }
  }
}

.dark-mode {
  .list-item {
    background: #3b3b3b;
    box-shadow: inset 0px -1px 0px #000000;
  }
}

@media only screen and (max-width: $mobile) {
  .list-item {
    &:hover {
      border: 0.3px solid transparent;
    }
    &-top {
      &-name {
        p {
          font-size: 0.813rem;
        }
      }
    }
    &-loader-lottie {
      position: absolute;
      left: 50%;
      top: -30px;
      transform: translate(-50%);
      width: 310px;
      height: 310px;
    }
  }
}
