.period-mobile {
  height: auto;
  flex-wrap: wrap;
  padding: 0px;
  position: relative;
  background: transparent;
  margin-bottom: 10px;

  &-menu {
    z-index: 99;
    position: absolute;
    top: 40px;
    left: 30px;
    width: 150px;
    font-size: 0.75rem;
    @extend .text;
    padding: 10px;
    background: #171725;
    color: white;
  }
  .countdown {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    &:before,
    &:after {
      display: none;
    }
  }
}
