@import "./Loader/style.scss";
.influencers-list-mobile {
  width: 100%;
  button {
    width: 100%;
    text-align: center;
    color: #92929d;
    font-size: 0.75rem;
    margin-top: 20px;
  }
  &-grid {
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}
