@import "./components/ListLoader/style.scss";
@import "./components/List/style.scss";
@import "./components/ListItemHashtagsCashtags/style.scss";
@import "./components/ListItemUrl/style.scss";
@import "./components/ListItem/style.scss";
@import "./components/ListItemLoader/style.scss";

.lists {
  order: 4;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 18px;
  position: relative;
  flex-direction: column;
  &-grid {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .list-loader {
    .lottie {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  &-hidden {
    .influencers {
      height: 0px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .lists {
    padding: 23px 0px 14px 0px;
  }
}
