.app {
  max-width: 1350px;
  width: calc(100% - 80px);
  margin: auto;
}

@media only screen and (max-width: $mobile) {
  .app {
    width: calc(100% - 25px);
    padding-top: 50px;
  }
}
