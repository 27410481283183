.period-sections-desktop {
  padding-top: 10px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-list {
    margin-top: auto;
  }
  &-title {
    font-weight: 500;
    @extend .text;
    margin-bottom: 8px;
    font-size: 0.875rem;
  }
}

.dark-mode {
  .home-header-counters {
    &-element {
      &-title {
        color: white;
      }
      &-count {
        color: white;
      }
    }
  }
}
