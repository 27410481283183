@import "./components/Influencer/style.scss";
@import "./components/InfluencersList/style.scss";
@import "./components/JoinBtn/style.scss";
@import "./components/MostActiveBtn/style.scss";

.influencers {
  margin-bottom: 21px;
  overflow: hidden;
  transition: 0.2s all;
  border-radius: 10px;
  height: 65px;

  position: relative;
  width: 100%;
  &-bg {
    transition: $theme-transition;
    background: #eeeeee;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
}

.dark-mode {
  .influencers {
    &-bg {
      background: #1e1e1e;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .influencers {
    flex-direction: column;
    height: auto;
    align-items: center;
    &-bg {
      display: none;
    }
  }
}
