.verified-users-popup {
  max-width: 420px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: inset 0px -1px 0px #dcdce0;
  border-radius: 15px;
  img {
    width: 41px;
    height: auto;
    object-fit: contain;
    margin-bottom: 15px;
  }
  h4 {
    @extend .text;
    font-size: 1.063rem;
    line-height: 1.75rem;

    font-weight: 700;
    margin-bottom: 20px;
  }
  .button {
    min-width: 190px;
  }
  span {
    font-style: italic;
  }
  p {
    font-size: 0.75rem;
    line-height: 1.5rem;
    a {
      margin-left: 5px;
      margin-right: 5px;
      color: #d5a229;
      font-weight: 900;
      text-decoration: none;
    }
    margin-bottom: 20px;
  }
}

.dark-mode {
  .verified-users-popup {
    background: #3b3b3b;
    box-shadow: none;
    p {
      color: white;
    }
  }
}
