.period-sections-mobile {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 14px;
  background: rgba(222, 222, 222, 0.2);
  border-radius: 15px;
  &-toggle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: 8px;

    border-top: 6px solid black;
    transform: scaleX(0.6);
  }
  &-header {
    height: 47px;
    align-items: center;
    justify-content: space-between;
    .flex {
      align-items: center;
      height: 100%;
    }
    h5 {
      font-size: 0.875rem;
      @extend .text;
      font-weight: 500;
    }
    button {
      font-size: 0.688rem;
      @extend .text;
      font-weight: 500;
      flex: 1;
      height: 100%;
      text-align: right;
    }
  }
  &-list {
    margin-top: auto;
    flex-direction: column;
    transition: 0.2s all;
    .period-element {
      height: 44px;
      width: 100%;
      flex-direction: row;
      padding: 0px;
      border-bottom: 1px solid #ececec;
      &:last-child {
        border: none;
      }
      &-title {
        margin-bottom: 0px;
        width: 80px;
      }
      &-count {
        font-size: 0.875rem;
      }
      &-content {
        width: calc(100% - 80px);
        justify-content: space-between;
      }

      &-name {
        font-size: 0.813rem;
        line-height: 1.219rem;
        padding-right: 20px;
      }
      h4 {
        align-self: center;
      }
      &::after {
        display: none;
      }
    }
  }
  &-title {
    font-weight: 500;
    @extend .text;
    margin-bottom: 8px;
    font-size: 0.688rem;
  }

  &-loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .loader {
      height: 15px;

      border-radius: 10px;
    }
    &-top {
      margin-bottom: 10px;
      .loader {
        width: 100px;
      }
    }
    &-bottom {
      justify-content: space-between;
      .loader {
        width: calc(100% / 4 - 20px);
      }
    }
  }
}

.dark-mode {
  .home-header-counters {
    &-element {
      &-title {
        color: white;
      }
      &-count {
        color: white;
      }
    }
  }
}

.dark-mode {
  .period-sections-mobile-toggle {
    border-top: 6px solid white;
  }
}
