.footer {
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 30px;
  position: relative;
  .privacy-policy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
